<template>
  <div class="content-wrapper">
    <PageHeader screenName="Empresas" :linkItems="linkItems" />

    <PendenciaModal
      :showModal="modalPendencia.showModal"
      @confirmed="closeModalPendencia"
    />

    <ConfirmModal
      itemIdentifier="usuário"
      :modalShow="modal.showModal"
      :typeModal="modal.typeModal"
      @confirmed="redirecionarLista"
      @canceled="closeModalConfirm"
    />

    <SucessoModal
      :modalShow="modalSucesso.showModal"
      :typeModal="modalSucesso.typeModal"
      :caseModal="modalSucesso.caseModal"
      @confirmed="redirecionarLista"
    />

    <DepartamentoModal
      :modalShow="modalErroRegiao.showModal"
      :typeModal="modalErroRegiao.caseModal"
      @confirmed="redirectToDepartamentoView"
    />

    <ModalEnderecoIncompleto
      :modalShow="modalErroEndereco.showModal"
      @confirmed="closeModalEnderecoIncompleto"
    />

    <TimedAlert
      variant="success"
      message="Empresa salva com sucesso!"
      :show="savedAlert"
    />
    <TimedAlert
      variant="warning"
      message="O estado selecionado é diferente do seu departamento regional!"
      :show="departamentoAlert"
    />
    <TimedAlert
      variant="success"
      message="Empresa salva com sucesso!"
      :show="savedAlert"
    />
    <TimedAlert
      variant="warning"
      message="O estado selecionado é diferente do seu departamento regional!"
      :show="departamentoAlert"
    />

    <b-card>
      <validation-observer ref="simpleRules">
        <b-tabs
          pills
          v-model="tabIndex"
          :vertical="vertical"
          class="displayResponsivo"
        >
          <!-- Tab: Dados principais -->
          <b-tab>
            <template #title>
              <span
                v-if="iconeErro.dadosPrincipais && tabIndex == 0"
                class="icone-erro-ativo"
              >
                <feather-icon icon="AlertCircleIcon" size="18" />
              </span>
              <span v-else-if="iconeErro.dadosPrincipais" class="icone-erro">
                <feather-icon icon="AlertCircleIcon" size="18" />
              </span>
              <span class="font-weight-bold">Dados principais</span>
            </template>
            <empresa-tab-dados-principais
              ref="tabDadosPrincipais"
              v-model="form.dadosPrincipais"
              :mudancaEstado.sync="cnpj"
              :travaSaving.sync="saving"
              :apagaErro400Api.sync="errorApi.dadosPrincipais.documento"
              :errorApi="errorApi.dadosPrincipais"
              :cnpjFaturamento.sync="mesmoCnpjFaturamento"
              :dataNascimentoForm.sync="form.dadosPrincipais.data_nascimento"
              :nomeCpfForm.sync="form.dadosPrincipais.nome"
              :ativarIconeAba.sync="iconeErro.dadosPrincipais"
              :alterarAba.sync="tabIndex"
              :disabledAll="false"
              :edicao="false"
              @dadosApiEmpresaCnpj="preencheDadosApiEmpresaCnpj"
              @dadosApiEmpresaCnpjVazio="cleanFormExecptDocument"
              class="mt-2 mb-2 pt-75"
            />
          </b-tab>

          <!-- Tab: Dados complementares -->
          <b-tab>
            <template #title>
              <span
                v-if="iconeErro.dadosComplementares && tabIndex == 1"
                class="icone-erro-ativo"
              >
                <feather-icon icon="AlertCircleIcon" size="18" />
              </span>
              <span
                v-else-if="iconeErro.dadosComplementares"
                class="icone-erro"
              >
                <feather-icon icon="AlertCircleIcon" size="18" />
              </span>
              <span class="font-weight-bold">Dados complementares</span>
            </template>
            <empresa-tab-dados-complementares
              :key="populated.dadosComplementares"
              ref="tabDadosComplementares"
              :pessoaEmpresa="cnpj"
              class="mt-2 mb-2 pt-75"
              v-model="form.dadosComplementares"
              :tipoEmpresa="tipoEmpresaSelecionada"
              @getTipoEmpresa="updateTipoEmpresa"
              :errorApi="errorApi.dadosComplementares"
              :naoIndustria="verificaSeEhNaoIndustria"
              :valorDiferenciadoFalso.sync="
                form.dadosComplementares.id_tipo_preco
              "
            />
          </b-tab>

          <!-- Tab: Faturamento -->
          <b-tab v-if="cnpj">
            <template #title>
              <span
                v-if="iconeErro.faturamento && tabIndex == 2"
                class="icone-erro-ativo"
              >
                <feather-icon icon="AlertCircleIcon" size="18" />
              </span>
              <span v-else-if="iconeErro.faturamento" class="icone-erro">
                <feather-icon icon="AlertCircleIcon" size="18" />
              </span>
              <span class="font-weight-bold">Faturamento</span>
            </template>
            <empresa-tab-dados-faturamento
              ref="tabDadosFaturamento"
              class="mt-2 mb-2 pt-75"
              v-model="form.faturamentoPrevio"
              :mesmoCnpjFaturamento="mesmoCnpjFaturamento"
              :errorApi="errorApi.faturamento"
              :getCnpjFaturamento.sync="form.faturamento.cnpj_faturamento"
            />
          </b-tab>

          <!-- Tab: Endereço -->
          <b-tab>
            <template #title>
              <span
                v-if="iconeErro.endereco && tabIndex == 2 && !cnpj"
                class="icone-erro-ativo"
              >
                <feather-icon icon="AlertCircleIcon" size="18" />
              </span>
              <span
                v-else-if="iconeErro.endereco && tabIndex == 3 && cnpj"
                class="icone-erro-ativo"
              >
                <feather-icon icon="AlertCircleIcon" size="18" />
              </span>
              <span v-else-if="iconeErro.endereco" class="icone-erro">
                <feather-icon icon="AlertCircleIcon" size="18" />
              </span>
              <span class="font-weight-bold">Endereço</span>
            </template>
            <empresa-tab-dados-endereco
              ref="tabDadosEndereco"
              class="mt-2 mb-2 pt-75"
              :preencheLogradouro.sync="form.endereco.logradouro"
              :preencheBairro.sync="form.endereco.bairro"
              :travaSaving.sync="saving"
              :cidade="cidadeSelecionada"
              :observerAditionalAddresses.sync="observerAditionalAddresses"
              :errorApi="errorApi.endereco"
              @disparaAlertaEstadoDepartamento="verificaDepartamento"
              @getCidade="updateCidade"
							@addedAddress="prepareEnderecoCompleto"
              @updateEnderecoTabContato="atualizaEnderecoTabContato"
              v-model="form.endereco"
            />
          </b-tab>

          <!-- Tab: Contato -->
          <b-tab>
            <template #title>
              <span v-if="iconeErro.contatoPrincipal && tabIndex == 3 && !cnpj" class="icone-erro-ativo"><feather-icon icon="AlertCircleIcon" size="18" /></span>
              <span v-else-if="iconeErro.contatoPrincipal && tabIndex == 4 && cnpj" class="icone-erro-ativo"><feather-icon icon="AlertCircleIcon" size="18" /></span>
              <span v-else-if="iconeErro.contatoPrincipal" class="icone-erro"><feather-icon icon="AlertCircleIcon" size="18" /></span>
              <span class="font-weight-bold">Contato</span>
            </template>
            <empresa-tab-dados-contato
              ref="tabDadosContato"
              class="mt-2 mb-2 pt-75"
              :errorApiContatoPrincipal="errorApi.contatoPrincipal"
              :errorApiEndereco.sync="errorApiEnderecoContato"
							:dadosContatoEndereco.sync="form.enderecoCompleto"
              v-model="form.contatoPrincipal"
            />
          </b-tab>
        </b-tabs>
      </validation-observer>
      <section class="buttons">
        <b-button
          id="save-empresa"
          class="mr-2 mt-2"
          variant="custom-blue"
          @click.prevent="validationForm(true)"
          :disabled="saving || savingAndNew"
        >
          <feather-icon icon="CheckIcon" class="mr-50" />
          <span class="align-middle">Salvar empresa</span>
          <span
            v-if="saving"
            class="spinner-border spinner-border-sm ml-1"
            role="status"
            aria-hidden="true"
          >
          </span>
        </b-button>
        <b-button
          id="save-empresa"
          class="mr-2 mt-2"
          variant="custom-blue"
          @click.prevent="validationForm(false)"
          :disabled="saving || savingAndNew"
        >
          <feather-icon icon="CheckIcon" class="mr-50" />
          <span class="align-middle">Salvar e cadastrar uma nova</span>
          <span
            v-if="savingAndNew"
            class="spinner-border spinner-border-sm ml-1"
            role="status"
            aria-hidden="true"
          >
          </span>
        </b-button>
        <b-button
          id="cancel-register-empresa"
          :disabled="saving || savingAndNew"
          variant="outline-danger"
          @click.prevent="openModalConfirm"
          class="mt-2"
        >
          <feather-icon icon="XIcon" class="mr-50" />
          <span class="align-middle">Cancelar</span>
        </b-button>
      </section>
    </b-card>
  </div>
</template>
<script>
import { BCard, BTab, BTabs, BButton } from "bootstrap-vue";
import EmpresaTabDadosPrincipais from "@/views/custom-pages/empresa/tabs-empresa/Empresa-tab-dados-principais.vue";
import EmpresaTabDadosComplementares from "@/views/custom-pages/empresa/tabs-empresa/Empresa-tab-dados-complementares.vue";
import EmpresaTabDadosContato from "@/views/custom-pages/empresa/tabs-empresa/Empresa-tab-contato.vue";
import EmpresaTabDadosEndereco from "@/views/custom-pages/empresa/tabs-empresa/Empresa-tab-endereco.vue";
import EmpresaTabDadosFaturamento from "@/views/custom-pages/empresa/tabs-empresa/Empresa-tab-faturamento.vue";
import PageHeader from "@/views/components/custom/page-header/PageHeader.vue";
import ConfirmModal from "@/views/components/custom/modals/ConfirmModal.vue";
import SucessoModal from "@/views/components/custom/modals/SucessoCriacaoEdicaoModal.vue";
import PendenciaModal from "@/views/components/custom/modals/empresa/PendenciaModal.vue";
import TimedAlert from "@/views/components/custom/timed-alert/TimedAlert.vue";
import alertaTodasAbas from "@/views/components/custom/timed-alert/alertaTodasAbas.vue";
import DepartamentoModal from "@/views/components/custom/modals/ModalErrorDepartamento.vue";
import { ValidationObserver } from "vee-validate";
import handleErrorsService from "./Services/HandleErrors";
import { actions, subjects } from "@/libs/acl/rules";
import { getUserData } from "@/auth/utils";
import ModalEnderecoIncompleto from "@/views/custom-pages/empresa/Modal/ModalEnderecoIncompleto.vue";
export default {
  title: "Cadastrar empresa",

  components: {
    BCard,
    BTab,
    BTabs,
    BButton,
    ValidationObserver,
    EmpresaTabDadosPrincipais,
    EmpresaTabDadosComplementares,
    EmpresaTabDadosFaturamento,
    EmpresaTabDadosEndereco,
    EmpresaTabDadosContato,
    PageHeader,
    ConfirmModal,
    SucessoModal,
    PendenciaModal,
    TimedAlert,
    alertaTodasAbas,
    DepartamentoModal,
    ModalEnderecoIncompleto
  },

  computed: {
    arrayComplementarVigiarObrigatorios() {
      const array = {
        documento: this.form.dadosPrincipais.documento,
        nomeFantasia: this.form.dadosPrincipais.nome_fantasia,
        razaoSocial: this.form.dadosPrincipais.razao_social,
        nomeCompleto: this.form.dadosPrincipais.nome,
        inscricaoEstadual: this.form.dadosPrincipais.inscricao_estadual,
        dataNascimento: this.form.dadosPrincipais.data_nascimento,
        cnae: this.form.dadosComplementares.cnae,
        tipoEmpresaSelecionado: this.tipoEmpresaSelecionada,
        radioCnpjFaturamento:
          this.form.faturamentoPrevio.radioMesmoCnpjFaturamento,
        cnpjFaturamento: this.form.faturamentoPrevio.cnpjFaturamento,
        cep: this.form.endereco.cep,
        logradouro: this.form.endereco.logradouro,
        bairro: this.form.endereco.bairro,
        descricao: this.form.endereco.descricao,
        cidadeSelecionada: this.cidadeSelecionada,
        nome: this.form.contatoPrincipal.nome,
        cargo: this.form.contatoPrincipal.cargo,
        email: this.form.contatoPrincipal.email,
        telefone: this.form.contatoPrincipal.telefone,
        observerAditionalAddresses: this.observerAditionalAddresses
      };

      if (!this.adminEmpresa) {
        array.unidadeSelecionada = this.unidadeSelecionada;
      }

      return array;
    },

    arrayVerificacaoCnpj() {
      const array = {
        documento: this.form.dadosPrincipais.documento,
        nomeFantasia: this.form.dadosPrincipais.nome_fantasia,
        razaoSocial: this.form.dadosPrincipais.razao_social,
        cnae: this.form.dadosComplementares.cnae,
        tipoEmpresaSelecionado: this.tipoEmpresaSelecionada,
        cnpjFaturamento: this.form.faturamentoPrevio.cnpjFaturamento,
        cep: this.form.endereco.cep,
        logradouro: this.form.endereco.logradouro,
        bairro: this.form.endereco.bairro,
        descricao: this.form.endereco.descricao,
        cidadeSelecionada: this.cidadeSelecionada,
        nome: this.form.contatoPrincipal.nome,
        cargo: this.form.contatoPrincipal.cargo,
        email: this.form.contatoPrincipal.email,
        telefone: this.form.contatoPrincipal.telefone,
        observerAditionalAddresses: this.observerAditionalAddresses
      };

      if (!this.adminEmpresa) {
        array.unidadeSelecionada = this.unidadeSelecionada;
      }

      return array;
    },

    arrayVerificacaoCnpjSemFaturamento(){
      const array = {
        documento: this.form.dadosPrincipais.documento,
        nomeFantasia: this.form.dadosPrincipais.nome_fantasia,
        razaoSocial: this.form.dadosPrincipais.razao_social,
        cnae: this.form.dadosComplementares.cnae,
        tipoEmpresaSelecionado: this.tipoEmpresaSelecionada,
        cep: this.form.endereco.cep,
        logradouro: this.form.endereco.logradouro,
        bairro: this.form.endereco.bairro,
        descricao: this.form.endereco.descricao,
        cidadeSelecionada: this.cidadeSelecionada,
        nome: this.form.contatoPrincipal.nome,
        cargo: this.form.contatoPrincipal.cargo,
        email: this.form.contatoPrincipal.email,
        telefone: this.form.contatoPrincipal.telefone,
        observerAditionalAddresses: this.observerAditionalAddresses
      };

      if (!this.adminEmpresa) {
        array.unidadeSelecionada = this.unidadeSelecionada;
      }

      return array;
    },

    arrayVerificacaoCpf() {
      const array = {
        documento: this.form.dadosPrincipais.documento,
        nomeCompleto: this.form.dadosPrincipais.nome,
        inscricaoEstadual: this.form.dadosPrincipais.inscricao_estadual,
        dataNascimento: this.form.dadosPrincipais.data_nascimento,
        cnae: this.form.dadosComplementares.cnae,
        tipoEmpresaSelecionado: this.tipoEmpresaSelecionada,
        cep: this.form.endereco.cep,
        logradouro: this.form.endereco.logradouro,
        bairro: this.form.endereco.bairro,
        descricao: this.form.endereco.descricao,
        cidadeSelecionada: this.cidadeSelecionada,
        nome: this.form.contatoPrincipal.nome,
        cargo: this.form.contatoPrincipal.cargo,
        email: this.form.contatoPrincipal.email,
        telefone: this.form.contatoPrincipal.telefone,
        observerAditionalAddresses: this.observerAditionalAddresses
      };

      if (!this.adminEmpresa) {
        array.unidadeSelecionada = this.unidadeSelecionada;
      }

      return array;
    },

    vertical() {
      return this.tamanhoTelaTempoReal < 980;
    },

    verificaSeEhNaoIndustria() {
      if (this.tipoEmpresaSelecionada == null) {
        return false;
      }

      return (
        this.tipoEmpresaSelecionada &&
        this.tipoEmpresaSelecionada.descricao == "Não Indústria"
      );
    },
  },

  data() {
    return {
      cnpj: true,
      tamanhoTelaTempoReal: 0,
      mesmoCnpjFaturamento: "",
      tabIndex: 0,
      cidadeSelecionada: null,
      userData: getUserData(),
      tipoEmpresaSelecionada: null,
      unidadeSelecionada: null,
      situacaoCadastro: true,
      departamentoAlert: false,
      modalErroRegiao: {
        showModal: false,
        caseModal: "sem-regiao-criacao-empresa",
      },
      modalErroEndereco: {
        showModal: false
      },
      form: {
        dadosPrincipais: {
          documento: "",
          nome_fantasia: null,
          razao_social: null,
          nome: null,
          inscricao_estadual: null,
          data_nascimento: null,
          genero: null,
          nome_mae: null,
          id_nacionalidade: null,
        },
        dadosComplementares: {
          cnae: null,
          fpas: null,
          numero_de_trabalhadores: null,
          id_tipo_empresa: null,
          base_nacional: false,
          valor_diferenciado: false,
          id_tipo_preco: null,
          possui_convenio: false,
          id_grupo: null,
          id_operador: null,
        },
        faturamento: {
          cnpj_faturamento: "",
        },
        endereco: {
          cep: null,
        	numero: null,
          ponto_referencia: null,
          id_cidade: null,
          logradouro: null,
          bairro: null,
          descricao: null,
					contato: {
						nome: null,
						cargo: null,
						email: null,
						telefone: null,
						telefone_secundario: null
					}   
        },
        contatoPrincipal:{
          nome: null,
          cargo: null,
          email: null,
          telefone: null,
          telefone_secundario: null,
        },
        faturamentoPrevio: {
          radioMesmoCnpjFaturamento: true,
          cnpjFaturamento: null,
        },
        enderecoCompleto: [],
      },
      errorApi: {
        dadosPrincipais: {
          documento: null,
          nome_fantasia: null,
          razao_social: null,
          nome: null,
          inscricao_estadual: null,
          data_nascimento: null,
          nome_mae: null,
          id_nacionalidade: "",
          error: "",
        },
        dadosComplementares: {
          cnae: null,
          fpas: null,
          numero_de_trabalhadores: null,
          id_tipo_empresa: null,
          valor_diferenciado: null,
          id_tipo_preco: null,
          possui_convenio: null,
          id_grupo: null,
          id_operador: null,
          error: ''
        },
        faturamento: {
          cnpj_faturamento: null,
          error: "",
        },
        endereco: {
          cep: null,
          numero: null,
          ponto_referencia: null,
          id_cidade: null,
          logradouro: null,
          bairro: null,  
          descricao: null,  
          error: ''
        },
        contatoPrincipal: {
          nome: null,
          cargo: null,
          email: null,
          telefone: null,
          telefone_secundario: null,
          error: "",
        }
      },
      modal: {
        showModal: false,
        typeModal: "danger",
      },
      modalSucesso: {
        showModal: false,
        typeModal: "success",
        caseModal: "criacao",
      },
      modalPendencia: {
        showModal: false,
        typeModal: "danger",
      },
      saving: false,
      savingAndNew: false,
      savedAlert: false,
      alert: {
        errorMessage: "",
        show: false,
      },
      linkItems: [
        {
          name: "Empresas",
          routeName: "empresa-list",
        },
        {
          name: "Cadastrar nova",
          active: true,
        },
      ],
      iconeErro: {
        dadosPrincipais: false,
        dadosComplementares: false,
        faturamento: false,
        endereco: false,
        contatoPrincipal: false
      },
      errorApiEnderecoContato: [],
      observerAditionalAddresses: true,
      adminEmpresa: this.verifyPermissions(actions.INSERIR),
      populated: {
        dadosPrincipais: false,
        dadosComplementares: false,
        dadosFaturamento: false,
        dadosEndereco: false,
        dadosContato: false
      },
    };
  },

  mounted() {
    let that = this
    this.tamanhoTelaTempoReal = window.innerWidth
    window.onresize = function() {
      that.tamanhoTelaTempoReal = window.innerWidth
    }

		this.prepareEnderecoCompleto();
  },

  methods: {
    openModalPendencia() {
      this.modalPendencia.showModal = true;
    },

    closeModalPendencia() {
      this.modalPendencia.showModal = false;
      this.$router.push({
        name: "empresa-list",
      });
    },

    closeModalEnderecoIncompleto() {
      this.modalErroEndereco.showModal = false
    },

    validationForm(redirectToList) {
      this.escondeIconesErrosAba();
      this.$refs.simpleRules.validate().then((success) => {
        if (success) {
          if (redirectToList) {
            this.saving = true;
          } else {
            this.savingAndNew = true;
          }
          this.cadastraEmpresa(redirectToList);
        } else {
          let arrayErrosIniciais = this.$refs.simpleRules.errors;
          let arrayNomeErros = [];
          for (const key in arrayErrosIniciais) {
            if (arrayErrosIniciais[key][0] != null)
              arrayNomeErros.push([key][0]);
          }
          this.verificaEMostraErrosIniciais(arrayNomeErros);
        }
      });
    },

    cadastraEmpresa(redirectToList){
      this.formataDadosParaRegistro()
      this.erasePreexistingErrors()
      this.escondeIconesErrosAba()

      const parameters =
      { 
        "empresaTipo": this.cnpj ? 'cnpj' : 'cpf' ,
        "dadosPrincipais": this.form.dadosPrincipais,
        "dadosComplementares": this.form.dadosComplementares,
        "endereco": this.form.enderecoCompleto,
        "contatoPrincipal": this.form.contatoPrincipal
      };

      if (this.cnpj) {
        parameters.cnpj_faturamento = this.form.faturamento.cnpj_faturamento;
      }

      this.$http
        .post(this.$api.saveAndGetEmpresa(), parameters)
        .then(() => {
          if (redirectToList) {
            this.saving = false;
          } else {
            this.savingAndNew = false;
            this.savedAlert = !this.savedAlert;
          }
          if (
            this.verifyPermissions(actions.INSERIR) &&
            this.form.dadosComplementares.cnae &&
            this.tipoEmpresaSelecionada &&
            this.tipoEmpresaSelecionada.descricao &&
            this.tipoEmpresaSelecionada.descricao === "Indústria" &&
            !this.verifyCNAERange(
              this.form.dadosComplementares.cnae.substring(0, 2)
            )
          ) {
            this.openModalPendencia();
          } else {
            this.openModalSucesso();
          }

          this.cleanForm();
        })
        .catch(({ response: { data } }) => {
          handleErrorsService.handleErrosEmpresa(
            data,
            this.errorApi,
            this.$refs.tabDadosEndereco.addressesErrorApi,
            this.form.enderecoCompleto.length,
            this.errorApiEnderecoContato
          );
          this.validaErroCidade(data.errors)
          this.redirecionadorErro();
          this.saving = false;
          this.savingAndNew = false;
        });
    },

    erasePreexistingErrors(){
      const tabDadosErrorApi = 
        [
          this.errorApi.dadosPrincipais,
          this.errorApi.dadosComplementares,
          this.errorApi.faturamento,
          this.errorApi.endereco,
          this.errorApi.contatoPrincipal
        ];
      this.valoresDeArraysParaNull(tabDadosErrorApi);
      this.$refs.simpleRules.reset();
    },

    cleanFormExecptDocument(){
      const originalDocument = this.form.dadosPrincipais.documento;
      this.cleanForm();
      this.form.dadosPrincipais.documento = originalDocument;
    },

    cleanForm() {
      this.$refs.tabDadosPrincipais.inputDataNascimento = ''
      this.mesmoCnpjFaturamento = ''
      this.cnpj = true
      this.cidadeSelecionada = null
      this.tipoEmpresaSelecionada = null
      this.unidadeSelecionada = null
      this.$refs.tabDadosEndereco.estadoSelecionado = null
      this.$refs.tabDadosEndereco.addresses = []
      this.$refs.tabDadosEndereco.addressesInternalUse = []
      this.$refs.tabDadosEndereco.addressesErrorApi = []
      this.observerAditionalAddresses = true
      const tabDados = 
        [ 
          this.form.dadosPrincipais,
          this.errorApi.dadosPrincipais,
          this.form.dadosComplementares,
          this.errorApi.dadosComplementares,
          this.form.faturamento,
          this.errorApi.faturamento,
          this.form.endereco,
          this.errorApi.endereco,
          this.form.contatoPrincipal,
          this.errorApi.contatoPrincipal,
          this.form.faturamentoPrevio
        ];
      this.valoresDeArraysParaNull(tabDados)
    
      this.$refs.simpleRules.reset();
    },

    valoresDeArraysParaNull(arrays) {
      for (const iterator of arrays) {
        this.valorDeArrayParaNull(iterator);
      }
    },

    validaErroCidade(erro) {
      Object.keys(erro).forEach(key => {
        if(erro[key][0] == 'É obrigatório informar uma cidade.') {
          this.modalErroEndereco.showModal = true
        }
      })
    },

    valorDeArrayParaNull(item) {
      Object.keys(item).forEach((key) => {
        if (key == "radioMesmoCnpjFaturamento") {
          item[key] = true;
        } else if (item[key] === true || item[key] === false) {
          item[key] = false;
        } else {
          item[key] = "";
        }
      });
    },

    redirecionarLista() {
      this.$router.push({ name: "empresa-list" });
    },

    openModalConfirm() {
      this.modal.showModal = true;
    },

    closeModalConfirm() {
      this.modal.showModal = false;
    },

    openModalSucesso() {
      this.modalSucesso.showModal = true;
    },

    updateCidade(cidade) {
      this.cidadeSelecionada = cidade;
    },

    verificaDepartamento(objetoDepartamento) {
      if (
        this.userData.departamento &&
        objetoDepartamento &&
        this.userData.departamento.uf &&
        this.userData.departamento.uf != objetoDepartamento.uf
      ) {
        setTimeout(() => {
          this.departamentoAlert = true;
        }, 100);
      }
      this.departamentoAlert = false;
      return;
    },

    updateTipoEmpresa(tipoEmpresa) {
      this.tipoEmpresaSelecionada = tipoEmpresa;
      this.populated.dadosComplementares = Math.random();
    },

    updateUnidade(unidade) {
      this.unidadeSelecionada = unidade;
    },

    formataDadosParaRegistro() {
      this.verificaEPreencheCnpjFaturamento();
      this.form.dadosComplementares.id_tipo_empresa = this
        .tipoEmpresaSelecionada
        ? this.tipoEmpresaSelecionada.id_tipo_empresa
        : null;
      this.form.endereco.id_cidade = this.cidadeSelecionada
        ? this.cidadeSelecionada.id_cidade
        : null;
      this.prepareIdCidadeMultipleAddresses();
      this.prepareEnderecoCompleto();
    },

    prepareIdCidadeMultipleAddresses() {
      for (const [
        index,
        value,
      ] of this.$refs.tabDadosEndereco.addressesInternalUse.entries()) {
        this.$refs.tabDadosEndereco.addresses[index].id_cidade =
          value.cidade && value.cidade.id_cidade
            ? value.cidade.id_cidade
            : null;
      }
    },

    prepareEnderecoCompleto() {
      this.form.enderecoCompleto = [];
      this.form.enderecoCompleto.push(this.form.endereco);
      for (const value of this.$refs.tabDadosEndereco.addresses) {
        if (!Object.values(value).every((x) => x === null || x === "")) {
          this.form.enderecoCompleto.push(value);
        }
      }
      this.preparaObjetoErroContatoEndereco();
    },

    atualizaEnderecoTabContato() {
      Object.values(this.form.enderecoCompleto).forEach(endereco => {
        if(endereco.contato == "") {
          endereco.contato = {
            logradouro: "",
            numero: "",
            bairro: "",
            descricao: "",
            cidade: "",
            estado: "",
            cep: ""
          }
        }
        endereco.contato.logradouro = endereco.logradouro;
        endereco.contato.numero = endereco.numero;
        endereco.contato.bairro = endereco.bairro;
        endereco.contato.descricao = endereco.descricao;
        endereco.contato.cidade = endereco.cidade;
        endereco.contato.estado = endereco.estado;
        endereco.contato.cep = endereco.cep;
      })
    },

    preparaObjetoErroContatoEndereco() {
      this.errorApiEnderecoContato = [];
      let contatoObjectAmount = 0;

      while(contatoObjectAmount < this.form.enderecoCompleto.length) {
        contatoObjectAmount++;
        this.errorApiEnderecoContato.push({
          nome: null,
          cargo: null,
          email: null,
          telefone: null,
          telefone_secundario: null,
          error: null
        })
      }
    },

    verificaEPreencheCnpjFaturamento() {
      if (this.cnpj) {
        if (this.form.faturamentoPrevio.radioMesmoCnpjFaturamento) {
          this.form.faturamento.cnpj_faturamento = this.$helpers.somenteNumeros(
            JSON.stringify(this.form.dadosPrincipais.documento)
          );
        } else {
          this.form.faturamento.cnpj_faturamento =
            this.form.faturamentoPrevio.cnpjFaturamento;
        }
      }
    },

    redirecionadorErro() {
      const emptyDadosPrincipais = Object.values(
        this.errorApi.dadosPrincipais
      ).every((x) => x === null || x === "");
      const emptyDadosComplementares = Object.values(
        this.errorApi.dadosComplementares
      ).every((x) => x === null || x === "");
      const emptyFaturamento = Object.values(this.errorApi.faturamento).every(
        (x) => x === null || x === ""
      );
      const emptyEndereco = Object.values(this.errorApi.endereco).every(
        (x) => x === null || x === ""
      );
      const emptyAditionalAddresses = this.verifyApiErrorAditionalAddresses(
        this.$refs.tabDadosEndereco.addressesErrorApi
      );
      const emptyContato = Object.values(this.errorApi.contatoPrincipal).every(
        (x) => x === null || x === ""
      );
      const arrayVerificadores = {
        dadosPrincipais: emptyDadosPrincipais,
        dadosComplementares: emptyDadosComplementares,
        faturamento: emptyFaturamento,
        endereco: emptyEndereco,
        aditionalAddresses: emptyAditionalAddresses,
        contato: emptyContato
      };
      this.mostraIconeErroAba(arrayVerificadores);
      if (this.cnpj) {
        this.redirecionaAbaErroCnpj(arrayVerificadores);
      } else {
        this.redirecionaAbaErroCpf(arrayVerificadores);
      }
    },

    verifyApiErrorAditionalAddresses(arrayApiErrorAditionalAddresses) {
      for (const aditionalAddress of arrayApiErrorAditionalAddresses) {
        if (
          !Object.values(aditionalAddress).every((x) => x === null || x === "")
        ) {
          return false;
        }
      }
      return true;
    },

    mostraIconeErroAba(emptyDados) {
      this.iconeErro.dadosPrincipais = !emptyDados.dadosPrincipais
        ? true
        : false;
      this.iconeErro.dadosComplementares = !emptyDados.dadosComplementares
        ? true
        : false;
      this.iconeErro.faturamento = !emptyDados.faturamento ? true : false;
      this.iconeErro.endereco =
        !emptyDados.endereco || !emptyDados.aditionalAddresses ? true : false;
      this.iconeErro.contatoPrincipal = !emptyDados.contatoPrincipal ? true : false;
    },

    redirecionaAbaErroCnpj(emptyDados) {
      if (!emptyDados.dadosPrincipais) {
        this.tabIndex = 0;
      } else if (!emptyDados.dadosComplementares) {
        this.tabIndex = 1;
      } else if (!emptyDados.faturamento) {
        this.tabIndex = 2;
      } else if (!emptyDados.endereco || !emptyDados.aditionalAddresses) {
        this.tabIndex = 3;
      } else if (!emptyDados.contatoPrincipal) {
        this.tabIndex = 4;
      }
    },

    redirecionaAbaErroCpf(emptyDados) {
      if (!emptyDados.dadosPrincipais) {
        this.tabIndex = 0;
      } else if (!emptyDados.dadosComplementares) {
        this.tabIndex = 1;
      } else if (!emptyDados.endereco || !emptyDados.aditionalAddresses) {
        this.tabIndex = 2;
      } else if (!emptyDados.contatoPrincipal) {
        this.tabIndex = 3;
      }
    },

    escondeIconesErrosAba() {
      this.iconeErro.dadosPrincipais = false;
      this.iconeErro.dadosComplementares = false;
      this.iconeErro.faturamento = false;
      this.iconeErro.endereco = false;
      this.iconeErro.contatoPrincipal = false;
    },

    verificacaoArrayEmpresaContemNulo(arrayVerificacao) {
      for (const prop in arrayVerificacao) {
        if (this.verificadorRetornaErroCasoNuloVazio(arrayVerificacao[prop])) {
          return 'Incompleto';
        }
      }
      return true;
    },

    verificadorRetornaErroCasoNuloVazio(prop) {
      if (prop === null || prop === "") {
        return true;
      }
    },

    verificaEMostraErrosIniciais(arrayNomesErros) {
      let abasComErros = {
        dadosPrincipais: true,
        dadosComplementares: true,
        faturamento: true,
        endereco: true,
        aditionalAddresses: true,
        contatoPrincipal: true,
      };
      abasComErros = this.retornaObjetoAbasComErroPrimario(
        arrayNomesErros,
        abasComErros
      );
      this.mostraIconeErroAba(abasComErros);
      if (this.cnpj) {
        this.redirecionaAbaErroCnpj(abasComErros);
      } else {
        this.redirecionaAbaErroCpf(abasComErros);
      }
    },

    retornaObjetoAbasComErroPrimario(arrayNomesErros, abasComErros) {
      for (const prop in arrayNomesErros) {
        if (
          (arrayNomesErros[prop] == "Documento" ||
            arrayNomesErros[prop] == "NomeFantasia" ||
            arrayNomesErros[prop] == "RazaoSocial" ||
            arrayNomesErros[prop] == "Sexo" ||
            arrayNomesErros[prop] == "DataNascimento" ||
            arrayNomesErros[prop] == "NomeCompleto" ||
            arrayNomesErros[prop] == "InscricaoEstadual") &&
          abasComErros.dadosPrincipais
        ) {
          abasComErros.dadosPrincipais = false;
        }
        if (
          (arrayNomesErros[prop] == "CNAE" ||
            arrayNomesErros[prop] == "FPAS") &&
          abasComErros.dadosComplementares
        ) {
          abasComErros.dadosComplementares = false;
        }
        if (
          arrayNomesErros[prop] == "CNPJFaturamento" &&
          abasComErros.faturamento
        ) {
          abasComErros.faturamento = false;
        }
        if (arrayNomesErros[prop].startsWith("CEP") && abasComErros.endereco) {
          abasComErros.endereco = false;
        }
        if (
          (arrayNomesErros[prop] == "Telefone" ||
            arrayNomesErros[prop] == "TelefoneSecundario") &&
          abasComErros.contatoPrincipal
        ) {
          abasComErros.contatoPrincipal = false;
        }
      }
      return abasComErros;
    },

    redirectToDepartamentoView() {
      this.$router.push({ name: "departamento-view" });
      this.modalErroRegiao.showModal = false;
    },

    async preencheDadosApiEmpresaCnpj(data) {
      this.form.dadosPrincipais.nome_fantasia = data.nomeFantasia;
      this.form.dadosPrincipais.razao_social = data.razaoSocial;
      this.form.dadosComplementares.cnae = data.cnae;
      this.$refs.tabDadosEndereco.externalApiFindingData = true;
      this.form.endereco.cep = data.cep;
      this.form.endereco.bairro = data.bairro;
      this.form.endereco.descricao = data.descricao;
      this.form.endereco.logradouro = data.logradouro;
      this.form.endereco.numero = data.numero;
      this.form.contatoPrincipal.telefone = data.telefonePrincipal;
      this.form.contatoPrincipal.telefone_secundario = data.telefoneSecundario;
      if (data.uf) {
        await this.preencheEstadoComUf(
          this.$helpers.formatStringLowerCaseWithoutAccent(data.uf)
        );
        await this.preencheCidadeComUfENomeCidade(
          this.$helpers.formatStringLowerCaseWithoutAccent(data.uf),
          this.$helpers.formatStringLowerCaseWithoutAccent(data.cidade)
        );
      }
      this.$refs.tabDadosEndereco.externalApiFindingData = false;
    },

    async preencheEstadoComUf(uf) {
      this.$refs.tabDadosEndereco.estadoSelecionado =
        this.$refs.tabDadosEndereco.estados.find(function (estado) {
          if (
            estado.uf
              .normalize("NFD")
              .replace(/[\u0300-\u036f]/g, "")
              .toLowerCase() == uf
          ) {
            return {
              nome: estado.nome,
              uf: estado.uf,
            };
          } else {
            return null;
          }
        });
      await this.$refs.tabDadosEndereco.buscaCidades(uf);
    },

    async preencheCidadeComUfENomeCidade(uf, nomeCidade) {
      if (
        this.$refs.tabDadosEndereco.estadoSelecionado &&
        this.$refs.tabDadosEndereco.cidades &&
        this.$refs.tabDadosEndereco.cidades != [] &&
        nomeCidade
      ) {
        this.cidadeSelecionada = this.$refs.tabDadosEndereco.cidades.find(
          function (cidade) {
            if (
              cidade.uf
                .normalize("NFD")
                .replace(/[\u0300-\u036f]/g, "")
                .toLowerCase() == uf &&
              cidade.nome
                .normalize("NFD")
                .replace(/[\u0300-\u036f]/g, "")
                .toLowerCase() == nomeCidade
            ) {
              return {
                id_cidade: cidade.id_cidade,
                nome: cidade.nome,
                uf: cidade.uf,
              };
            } else {
              return null;
            }
          }
        );
      }
    },

    verifyCNAERange(twoNumbers) {
      return twoNumbers >= 5 && twoNumbers <= 33;
    },

    verifyPermissions(action) {
      return this.$can(actions[action], subjects.EMPRESAS_AE);
    },
  },

  watch: {
    arrayComplementarVigiarObrigatorios() {
      if (this.cnpj && this.form.faturamentoPrevio.radioMesmoCnpjFaturamento) {
        this.situacaoCadastro = this.verificacaoArrayEmpresaContemNulo(
          this.arrayVerificacaoCnpjSemFaturamento
        );
      } else if (
        this.cnpj &&
        !this.form.faturamentoPrevio.radioMesmoCnpjFaturamento
      ) {
        this.situacaoCadastro = this.verificacaoArrayEmpresaContemNulo(
          this.arrayVerificacaoCnpj
        );
      } else if (!this.cnpj) {
        this.situacaoCadastro = this.verificacaoArrayEmpresaContemNulo(
          this.arrayVerificacaoCpf
        );
      } else {
        this.situacaoCadastro = true;
      }
    },
    cnpj: function (newVal) {
      if (!newVal) {
        this.tipoEmpresaSelecionada =
          this.$refs.tabDadosComplementares.tiposEmpresa.find(function (
            objeto
          ) {
            if (objeto.descricao == "Não Indústria") {
              return {
                id_tipo_empresa: objeto.id_tipo_empresa,
                descricao: objeto.descricao,
              };
            } else {
              return null;
            }
          });
      } else {
        this.tipoEmpresaSelecionada = null;
      }
    },
    "form.dadosComplementares.cnae": {
      deep: true,
      handler: function (after) {
        if (
          after != null &&
          after.length == 7 &&
          this.verifyCNAERange(parseInt(after[0] + after[1])) &&
          this.cnpj
        ) {
          this.tipoEmpresaSelecionada =
            this.$refs.tabDadosComplementares.tiposEmpresa.find(function (
              objeto
            ) {
              if (objeto.descricao == "Indústria") {
                return {
                  id_tipo_empresa: objeto.id_tipo_empresa,
                  descricao: objeto.descricao,
                };
              }
            });
        }
      },
    },
  },
};
</script>
<style>
.font_size_label_empresa {
  font-size: 15px;
}
.nav-pills .nav-link.active {
  border-color: #2772c0;
  background-color: #2772c0;
}
.icone-erro {
  color: red;
  margin-right: 5px;
}
.icone-erro-ativo {
  color: white;
  margin-right: 5px;
}
@media (max-width: 750px) {
  .displayResponsivo {
    display: block;
  }
}
</style>